<template>
  <div>
    <el-card class="box-card">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item><a @click="request">首页</a></el-breadcrumb-item>
        <el-breadcrumb-item>自有申报</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="hr-20"></div>
      <el-table
        :data="tableData"
        stripe
        style="width: 100%"
        v-show="open === false"
      >
        <el-table-column prop="policyTitle" label="政策名称">
          <template slot-scope="scope">
            <el-tag size="small" type="danger" v-if="!scope.row.policyTitle"
              >此申报对应政策表单已删除</el-tag
            >
            <span
              v-else
              @click="seeDateiled(scope.row)"
              style="cursor: pointer;"
              >{{ scope.row.policyTitle }}</span
            >
          </template>
        </el-table-column>
        <!--                <el-table-column prop="id" label="编号"></el-table-column>-->
        <el-table-column label="申报时间" width="200" align="center">
          <template slot-scope="scope">{{
            scope.row.createTime | formatDate
          }}</template>
        </el-table-column>
        <el-table-column label="类型" width="200" align="center">
          <template> 单位政策</template>
        </el-table-column>
        <el-table-column label="状态" width="200" align="center">
          <template slot-scope="scope">
            <el-tag type="info" v-if="scope.row.currentStatus === 1"
              >已提交</el-tag
            >
            <el-tag type="success" v-if="scope.row.currentStatus === 21"
              >管理员通过</el-tag
            >
            <el-tag type="warning" v-if="scope.row.currentStatus === 20"
              >管理员驳回</el-tag
            >
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              size="mini"
              plain
              v-if="!scope.row.policyTitle"
              :disabled="true"
              >查看详情</el-button
            >
            <el-button
              size="mini"
              type="primary"
              plain
              v-else
              @click="seedetailed(scope.row)"
              >查看详情</el-button
            >
            <span v-if="scope.row.currentStatus === 21" style="padding: 0 8px;">
              <el-button
                size="mini"
                type="primary"
                v-if="scope.row.complete === 0"
                @click="feedback(scope.row)"
                >领取反馈</el-button
              >
              <el-button
                v-else
                type="success"
                @click="lookopen(scope.row)"
                plain
                size="mini"
                >查看反馈</el-button
              >
            </span>
          </template>
        </el-table-column>
      </el-table>

      <el-table
        :data="newData"
        stripe
        style="width: 100%"
        v-show="open === true"
      >
        <el-table-column prop="policyTitle" label="政策名称">
          <template slot-scope="scope">
            <span @click="seedetailed(scope.row)" style="cursor: pointer;">{{
              scope.row.policyTitle
            }}</span>
          </template>
        </el-table-column>
        <el-table-column label="申报时间" width="200" align="center">
          <template slot-scope="scope">{{
            scope.row.createTime | formatDate
          }}</template>
        </el-table-column>
        <el-table-column label="类型" width="200" align="center">
          <template> 单位政策</template>
        </el-table-column>
        <el-table-column label="状态" width="200" align="center">
          <template>
            <el-tag type="info">未提交</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="primary"
              plain
              @click="requestDraft(scope.row)"
              >填写</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-button
        size="mini"
        class="fr"
        plain
        type="info"
        style="margin: 8px;"
        @click="open = !open"
        v-show="open === false"
        >草稿箱</el-button
      >
      <el-button
        size="mini"
        class="fr"
        plain
        type="info"
        style="margin: 8px;"
        @click="open = !open"
        v-show="open === true"
        >返 回</el-button
      >

      <el-pagination
        id="pagination"
        background
        layout="prev, pager, next"
        :total="total"
        :page-size="pageSize"
        v-model="pages"
        @current-change="requestPage"
      ></el-pagination>
    </el-card>

    <el-dialog
      v-model="createDialog"
      :visible.sync="createDialog"
      width="95%"
      @close="handleClose"
    >
      <el-row :gutter="5">
        <el-col :span="22">
          <el-card v-loading="createLoading" shadow="never">
            <div>填报内容</div>
            <div class="shenbxx">
              <span
                :title="item.value"
                v-for="(item, k) in detailsData"
                :key="k"
                :style="'display:' + item.type"
                >{{ item.label }}：
                {{ item.value }}
              </span>
            </div>
          </el-card>
          <div class="hr-10"></div>
          <el-card v-loading="createLoading" shadow="never">
            <div>附件材料查看下载</div>
            <div class="shenbxx">
              <template v-for="(item, i) in filelis">
                <el-button
                  :key="i"
                  type="primary"
                  size="small"
                  icon="el-icon-postcard"
                  plain
                  v-if="item.path && item.name"
                  @click="downloaded(item.path)"
                >
                  {{ item.name }}
                </el-button>
              </template>
            </div>
          </el-card>

          <div class="hr-20"></div>

          <el-card v-show="status === 20">
            <div>驳回理由</div>
            <el-input type="textarea" v-model="memo"></el-input>
          </el-card>
        </el-col>
        <el-col :span="2">
          <div class="fl" style="margin-left: 16px">照片:</div>

          <div v-for="(item, i) in filelis" :key="i">
            <img
              v-if="iSBoolean(item.name, '头像')"
              :src="fileurl + item.path"
              width="75%"
              height="75%"
              alt
            />
          </div>
        </el-col>
      </el-row>
      <div id="dialog-btn">
        <el-button type="warning" plain @click="createDialog = !createDialog"
          >返回上一级</el-button
        >
      </div>
    </el-dialog>

    <el-dialog
      title="反馈"
      :visible.sync="dialogfeedback"
      width="560px"
      :before-close="handleClose"
      :showClose="showClose"
    >
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="金额">
          <el-input v-model="form.money"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="form.remarks"></el-input>
        </el-form-item>

        <div
          style="color: red;font-size: 12px;position: absolute;left: 100px;bottom: 98px;"
        >
          * 反馈机会仅有一次，请耐心等待至打款金额到账后再反馈
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="submitFeedback">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog title="反馈内容" :visible.sync="dialogOne" width="560px">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="金额">
          <el-input v-model="form.money" readonly=""></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input
            type="textarea"
            readonly=""
            v-model="form.remarks"
            placeholder="“已领取5000元补贴，期待下次发放补贴，希望经开区越来越好！”"
          ></el-input>
        </el-form-item>
        <div style="margin-left: -220px">
          反馈时间: {{ form.createTime | formatDate }}
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogOne = !dialogOne">关 闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
//import headtitle from "@/components/ControlPanel/headtitle";
import configDate from "@/utils/config";

export default {
  components: {
    //headtitle
  },
  data() {
    return {
      fileurl: configDate.fileurl,
      dialogOne: false,
      dialogfeedback: false,
      userId: sessionStorage.getItem("ID"),
      createLoading: false,
      createDialog: false,
      showClose: false,
      open: false,
      memo: "",
      page: 1,
      pages: 0,
      pageSize: 10,
      total: 1,
      searchKeyword: "",
      form: {
        money: "",
        remarks: "",
      },
      tableData: [],
      newData: [],
      detailsData: [],
      get: [],
      filelis: [],
      putData: {
        id: "",
        status: [],
        currentStatus: "",
      },
      tabletSatuses: {
        affairId: "",
        userId: "",
      },
      status: 0,
      affairId: "",
    };
  },
  created() {
    this.getDate(); //初始化数据
    this.requestAffair();
    if (this.$route.query.draft === true) {
      this.open = true;
    }
  },
  methods: {
    request() {
      let newPage = this.$router.resolve({
        name: "EntryStoma",
      });
      window.open(newPage.href, "_blank");
    },

    cancel() {
      this.dialogfeedback = false;
      this.form = {};
    },

    feedback(row) {
      this.dialogfeedback = true;
      this.affairId = row.id;
    },

    requestDraft(row) {
      this.$router.push({
        path: "/qydeclarefrom/" + row.policyId + "&" + row.id,
      });
    },

    lookopen(row) {
      this.dialogOne = true;
      this.$axios.get("/affair?id=" + row.id).then((response) => {
        this.tabletSatuses.affairId = response.data.data.id;
        this.tabletSatuses.userId = response.data.data.ownerId;

        this.$axios
          .get(
            "/affair/feedback?affairId=" +
              this.tabletSatuses.affairId +
              "&userId=" +
              this.tabletSatuses.userId
          )
          .then((response) => {
            this.form.money = response.data.data.content.split("===备注：")[0];
            this.form.remarks = response.data.data.content.split(
              "===备注："
            )[1];
            this.form.createTime = response.data.data.createTime;
          });
      });
    },

    submitFeedback() {
      const self = this;
      this.$axios
        .post("/affair/feedback", {
          ownerId: sessionStorage.getItem("ID"),
          affairId: self.affairId,
          content: self.form.money + "￥===备注：" + self.form.remarks,
        })
        .then(function(res) {
          console.log(res);
          if (res.data.status === 200) {
            self.dialogfeedback = false;
            self.$notify({
              title: "成功",
              message: res.data.data,
              type: "success",
            });
            self.form = {};
            self.getDate(self.page);
          } else {
            self.$root.warn("领取失败");
          }
        })
        .catch((error) => {
          self.dialogfeedback = false;
          self.$root.warn("服务器异常");
        });
    },

    getDate() {
      this.$axios
        .get("/affair/page", {
          params: {
            userId: this.userId,
            current: 1,
            type: sessionStorage.getItem("Leix"),
            size: this.pageSize,
          },
        })
        .then((response) => {
          console.log("-----");
          console.log(response);
          if (response.data.status === 200) {
            this.tableData = response.data.data.records;
            this.total = response.data.data.total;
          } else if (response.data.data === "Access denide") {
            this.$router.push("/login?name=second");
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
    requestAffair() {
      this.$axios
        .get("/affair/drafts", {
          params: {
            userId: sessionStorage.getItem("ID"),
            current: 1,
            size: this.pageSize,
          },
        })
        .then((response) => {
          if (response.data.status === 200) {
            this.newData = response.data.data.records;
            self.total = response.data.data.total;
          }
        });
    },

    seeDateiled(row) {
      this.$router.push({ path: "/declareitem/declarewz/" + row.policyId });
    },

    // 分页
    requestPage(value) {
      this.$axios
        .get("/affair/page", {
          params: {
            current: value,
            size: this.pageSize,
          },
        })
        .then((response) => {
          this.tableData = response.data.data.records;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    //  查看详情
    seedetailed(row) {
      const self = this;
      this.$axios
        .get("/affair?id=" + row.id)
        .then((response) => {
          const policyId = response.data.data.policyId;
          const id = response.data.data.id;
          self.putData.id = response.data.data.id;
          self.putData.status = response.data.data.status;
          self.tabletSatuses.userId = response.data.data.ownerId;
          self.tabletSatuses.affairId = response.data.data.id;

          self.$axios
            .get(
              "/affair/statuses?affairId=" +
                self.tabletSatuses.affairId +
                "&userId=" +
                self.tabletSatuses.userId
            )
            .then((response) => {
              self.affairStatusId =
                response.data.data[response.data.data.length - 1].id;
              self.$axios
                .get("/affair/status?affairStatusId=" + self.affairStatusId)
                .then((response) => {
                  self.memo = response.data.data.content;
                  self.status = response.data.data.status;
                });
            });

          self.$axios
            .get("/policy?id=" + policyId)
            .then(function(res) {
              let newarr = [];
              res.data.data.form.inputs.forEach(function(item) {
                //   item.name.indexOf("Upload") !== -1 ? "none" : "blick";
                let ThisType = item.type === "file" ? "none" : "blick";
                newarr.push({
                  type: ThisType,
                  label: item.label,
                  value: "",
                  [item.name]: "",
                });
              });

              //事务详情
              self.$axios
                .get("/affair?id=" + id)
                .then(function(res) {
                  let separate = [];
                  res.data.data.values.forEach(function(item) {
                    if (item.name.indexOf("Upload") != -1) {
                      self.getFlelis(item.value);
                    } else {
                      separate.push(item);
                    }
                  });
                  separate.forEach(function(item, i) {
                    newarr.forEach(function(list, index) {
                      if (item.label === list.label) {
                        // newarr[index].value = item.value.split(',"')[1].split('"]')[0]
                        newarr[index].value = item.value;
                      }
                    });
                  });

                  res.data.data.values.forEach(function(item) {});

                  self.createDialog = true;
                })
                .catch(function(error) {
                  console.error(error);
                });
              self.detailsData = newarr;
            })
            .catch(function(error) {
              console.error(error);
            });
        })
        .catch((error) => {
          this.createLoading = false;
          console.error(error);
        });
    },
    //下载
    downloaded(path) {
      window.location.href = this.fileurl + path;
    },
    getFlelis(ID) {
      var self = this;
      this.$axios.get("/file/record?id=" + ID).then(function(res) {
        self.filelis.push({
          name: res.data.data.memo,
          path: res.data.data.path,
        });
      });
    },
    handleClose() {
      this.detailsData = [];
      this.filelis = [];
    },
    //办理
    handle(status, memo) {
      const self = this;
      this.putData.currentStatus = status;
      this.putData.status.push({
        memo: memo,
        status: status,
      });
      if (confirm("确实要更新吗?")) {
        this.$axios.put("/affair", this.putData).then(function(res) {
          if (res.data.status === 200) {
            self.$notify({
              title: "成功",
              message: res.data.data,
              type: "success",
            });
          }
        });
      } else {
        self.$notify({
          title: "成功",
          message: "取消更新！",
          type: "success",
        });
      }
    },
  },
  computed: {
    // 控制显示的内容
    iSBoolean() {
      return function(value1, value2) {
        return value1 === value2;
      };
    },
  },
};
</script>

<style lang="less" scoped>
.speeditem {
  h4 {
    margin: 0;
  }

  p {
    margin: 3px;
  }
}

.span {
  background: #f3f3f3;
  display: block;
  height: 40px;
  font-size: 16px;
  text-align: center;
  padding: 0;
  line-height: 35px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  margin: 16px;
}

.shenbxx {
  overflow: hidden;
  font-size: small;
  margin-top: 8px;

  span {
    background: #efefef;
    display: block;
    height: 35px;
    text-align: left;
    padding: 0 0 0 10px;
    line-height: 35px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    float: left;
    width: 30%;
    margin: 9px;
  }
}

#pagination {
  margin: 32px 0 16px 0;
}

#dialog-btn {
  margin: 32px;
}
</style>
